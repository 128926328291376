import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import kebabCase from 'lodash/kebabCase'
import { WSAEINPROGRESS } from 'constants'

const defaultText = `THIS IS A HEADER
================

• List Item 1
• List Item 2
`

/*

VIA: https://developer.apple.com/app-store/product-page/

Name: 0-30 characters

Subtitle: 0-30 characters

Description: 0-255 before truncated, min: 80, max: 4_000

Promotional Text:

Keywords: 100 characters

*/

class HexValueField extends React.Component {
  constructor(props) {
    super(props)
    this.state = { submittedValue: '' }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const submittedValue = parseFloat(event.target.value)
    console.log(submittedValue)
    const convertedValue = Math.trunc(submittedValue * 255).toString(16)
    console.log(convertedValue)
    this.setState({ submittedValue, convertedValue })
    //   value: event.target.value

    // })
  }

  handleSubmit(event) {
    console.log('A name was submitted: ' + this.state.value)
    event.preventDefault()
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          Name:
          <input
            type="number"
            value={this.state.submittedValue}
            onChange={this.handleChange}
          />
          <input type="text" value={this.state.convertedValue} />
        </label>
        <input type="submit" value="Submit" />
      </form>
    )
  }
}

class AppStoreDescriptionSection extends React.Component {
  constructor(props) {
    super(props)

    this.titleChanged = this.titleChanged.bind(this)
    this.bodyChanged = this.bodyChanged.bind(this)
  }

  titleChanged(event) {}

  bodyChanged(event) {}

  render() {
    return (
      <div className="cell grid-x grid-padding-x">
        <form className="cell small-6">
          <label>
            Section Title:
            <input
              type="text"
              value={this.props.heading}
              onChange={this.titleChanged}
            />
          </label>
          <label>
            Content:{' '}
            <span style={{ textAlign: 'right', float: 'right' }}>
              <a>list</a> | text
            </span>
            <textarea rows="4" type="text" value={this.props.heading} />
          </label>
        </form>
        <div className="cell small-6">
          <p>
            <span style={{ textTransform: 'uppercase' }}>
              {this.props.heading}
            </span>
            <br />
            {this.props.content}
          </p>
        </div>
        <hr className="cell" />
      </div>
    )
  }
}

const AppStoreNotesPage = props => {
  const site = get(props, 'data.site')

  return (
    <div className="grid-container">
      <div className="grid-x grid-padding-x">
        <div className="cell">
          <AppStoreDescriptionSection
            heading="Heading One"
            content="Lorem ipsum, dolor sit amet!"
          />
          <AppStoreDescriptionSection />
          <AppStoreDescriptionSection />
          <AppStoreDescriptionSection />
        </div>
      </div>

      <Helmet
        title="App Store Text Formatter"
        meta={[
          {
            name: 'description',
            content:
              'A reference site for iOS developers to quickly find links to relevant documentation and examples.',
          },
          {
            name: 'keywords',
            content:
              'ios, mobile, iphone, apps, developer, software development',
          },

          { name: 'twitter:card', content: 'summary' },
          { name: 'twitter:site', content: '@iosdevrecipes' },
          {
            name: 'twitter:title',
            content: 'iOS Dev Recipes',
          },
          {
            name: 'twitter:description',
            content: 'A reference site for iOS developers.',
          },
          {
            name: 'twitter:image',
            content: 'https://www.iosdev.recipes/img/logos/logo-2048.png',
          },
          {
            name: 'og:title',
            content: 'App Store Text Formatter',
          },
          {
            name: 'og:description',
            content:
              'A reference site for iOS developers to quickly find links to relevant documentation and examples.',
          },
          {
            name: 'og:image',
            content: 'https://www.iosdev.recipes/img/logos/logo-2048.png',
          },
        ]}
      />
    </div>
  )
}

export default AppStoreNotesPage

export const query = graphql`
  query AppTextEditorQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
